.App {
  text-align: center;
}

.bg-neutral {
  background-color: #ededed;
}

.main {
  background-color: #ebebeb;
  padding-top: 80px;
}

main > .container,
main > .container-xs,
main > .container-sm,
main > .container-md,
main > .container-lg,
main > .container-xl,
main > .container-fluid,
main > div
{
  padding-bottom: 70px;
  width: 90%;
}

nav {
  padding: 20px 0 !important;
}

nav > div {
  width: 90%;
}
